import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
// import { AppDispatch, RootState } from "./store";
// import { selectBusinesses } from "./businessListSlice";
import { Subject, Business, Residence } from "../types";

type ZipCodeListState = {
  selectedZipCodes: string[];
};

const initialState: ZipCodeListState = {
  selectedZipCodes: [],
};

const zips = createSlice({
  name: "zipCodes",
  initialState,
  reducers: {
    setFilterZipCodes: (state, action: PayloadAction<string>) => {
      const index = state.selectedZipCodes.indexOf(action.payload);
      if (index >= 0) {
        state.selectedZipCodes.splice(index, 1);
      } else {
        state.selectedZipCodes.push(action.payload);
      }
    },
  },
});

export const { setFilterZipCodes } = zips.actions;

// Thunks

export const selectFilterZipCodes = (state: RootState) => state.zipCodes.selectedZipCodes;

const zipFromSubjectsFn = <S extends Subject>(subjects: S[]): string[] => {
  const zipCounts = subjects.reduce((acc, business) => {
    if (!business.zipCode) return acc;

    const zipFive = String(business.zipCode).substring(0, 5);
    acc[zipFive] = (acc[zipFive] || 0) + 1;
    return acc;
  }, {} as Record<string, number>);

  console.log(zipCounts);

  const [zipMain, zipOther] = Object.keys(zipCounts).reduce((acc, zip) => {
    if (zipCounts[zip] >= 100) {
      acc[0].push(zip);
    } else {
      acc[1].push(zip);
    }
    return acc;
  }, [[], []] as [string[], string[]]);

  const zipHead = zipMain
    .sort((a, b) => zipCounts[b] - zipCounts[a])
    .map((zip) => `${zip} (${zipCounts[zip]})`);

  const zipTailCount = zipOther.reduce((acc, zip) => {
    return acc + zipCounts[zip];
  }, 0);

  return [...zipHead, `Other (${zipTailCount})`];
};

export const selectBusinessZipCodes = createSelector(
  [(state: RootState) => state.businessList.businesses],
  zipFromSubjectsFn<Business>
);

export const selectResidenceZipCodes = createSelector(
  [(state: RootState) => state.residenceList.residences],
  zipFromSubjectsFn<Residence>
);

export default zips.reducer;
