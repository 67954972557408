import { View } from "react-native";

import DetailsView from "./BusinessDetails";
import FilterButton from "./filterButton";
import BusinessListSearch from "./businessListSearch";
import SubjectList from "./SubjectList";

import SubjectNavigator from "./SubjectNavigator";
import ZipCodeFilterButton from "./shared/ZipCodeFilterButton";
import AssigneeFilterButton from "./shared/AssigneeFilter";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { useTheme } from "react-native-paper";
import notesAttachmentsStyles from "../Styles/notesAttachmentsStyles";

const Tabs = createMaterialTopTabNavigator();

const BusinessListScreen = () => {
  const theme = useTheme();
  const viewOffset = -12;

  return (
    <>
      <View
        style={{
          marginTop: 16,
          marginRight: 16,
        }}
      >
        <BusinessListSearch />
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: viewOffset,
          }}
        >
          <FilterButton />
          <ZipCodeFilterButton type="Business" />
          <AssigneeFilterButton />
        </View>
      </View>
      <Tabs.Navigator
        initialRouteName="to-do"
        style={notesAttachmentsStyles.tabNav}
        sceneContainerStyle={notesAttachmentsStyles.tabScreen}
        screenOptions={{
          tabBarActiveTintColor: theme.colors.primary,
          tabBarStyle: {
            backgroundColor: theme.colors.background,
          },
          tabBarLabelStyle: {
            color: theme.colors.onBackground,
          },
        }}
      >
        <Tabs.Screen
          name="to-do"
          options={{
            title: "Digital Canvas | Business Insights",
            tabBarLabel: "To Do",
          }}
        >
          {() => (
            <SubjectList.Businesses />
          )}
        </Tabs.Screen>

        <Tabs.Screen
          name="done"
          options={{
            title: "Digital Canvas | Business Insights",
            tabBarLabel: "Done",
          }}
        >
          {() => (
            <SubjectList.Businesses terminal />
          )}
        </Tabs.Screen>
      </Tabs.Navigator>
    </>
  );
};

const BusinessListNavigation = () => {
  return (
    <SubjectNavigator
      subjectType="Business"
      listComponent={BusinessListScreen}
      detailComponent={DetailsView}
    />
  );
};

export default BusinessListNavigation;
