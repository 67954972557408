import { useMemo } from "react";
import SparkMD5 from "spark-md5";

// useItemsForPins returns a capped list of memoized items, and a boolean
// indicating if the list was truncated or not. Use this to avoid rendering
// too many pins on a map and causing performance issues.
const useItemsForPins = <T extends { id: string }>(items: T[], maxPins = 2250) => {
  const md5Sum = useMemo(() => {
    const spark = new SparkMD5();
    items.slice(0, maxPins).forEach((item) => {
      spark.append(item.id);
    });

    return spark.end();
  }, [items, maxPins]);

  const itemsForMarkers = useMemo(() => {
    return items.slice(0, maxPins);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [md5Sum]);

  const hiddenItemCount = items.length - maxPins;
  const hasHiddenItems = hiddenItemCount > 0 ? hiddenItemCount : 0;
  return [itemsForMarkers, hasHiddenItems] as const;
};

export default useItemsForPins;
