import { useCallback } from "react";
import { View } from "react-native";

import { useAppSelector } from "../../redux/hooks";
import {
  selectDoneResidences,
  selectLoadingResidences,
  selectToDoResidences,
} from "../../redux/residenceListSlice";
import { Residence } from "../../lib/types/residence";

import CommonList from "../SubjectList/CommonList";
import FilterButton from "./FilterButton";
import ResidencePreview from "./ResidencePreview";
import Search from "./Search";
import AssigneeFilterButton from "../shared/AssigneeFilter";
import ZipCodeFilterButton from "../shared/ZipCodeFilterButton";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { useTheme } from "react-native-paper";
import notesAttachmentsStyles from "../../Styles/notesAttachmentsStyles";

const Tabs = createMaterialTopTabNavigator();

export default function ResidencesList() {
  const isLoading = useAppSelector(selectLoadingResidences);
  const toDoResidences = useAppSelector(selectToDoResidences);
  const doneResidences = useAppSelector(selectDoneResidences);
  const renderResidence = useCallback((item: Residence) => {
    return <ResidencePreview key={item.id} residence={item} />;
  }, []);

  const theme = useTheme();
  const viewOffset = -12;

  return (
    <>
      <View
        style={{
          marginTop: 16,
          marginRight: 16,
        }}
      >
        <Search />
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            marginRight: viewOffset,
          }}
        >
          <FilterButton />
          <ZipCodeFilterButton type="Residence" />
          <AssigneeFilterButton />
        </View>
      </View>
      <Tabs.Navigator
        initialRouteName="to-do"
        style={notesAttachmentsStyles.tabNav}
        sceneContainerStyle={notesAttachmentsStyles.tabScreen}
        screenOptions={{
          tabBarActiveTintColor: theme.colors.primary,
          tabBarStyle: {
            backgroundColor: theme.colors.background,
          },
          tabBarLabelStyle: {
            color: theme.colors.onBackground,
          },
        }}
      >
        <Tabs.Screen
          name="to-do"
          options={{
            title: "Digital Canvas | Residence Insights",
            tabBarLabel: "To Do",
          }}
        >
          {() => (
            <CommonList
              subjectType="Residence"
              isLoading={isLoading}
              subjects={toDoResidences}
              renderSubject={renderResidence}
            />
          )}
        </Tabs.Screen>

        <Tabs.Screen
          name="done"
          options={{
            title: "Digital Canvas | Residence Insights",
            tabBarLabel: "Done",
          }}
        >
          {() => (
            <CommonList
              subjectType="Residence"
              isLoading={isLoading}
              subjects={doneResidences}
              renderSubject={renderResidence}
            />
          )}
        </Tabs.Screen>
      </Tabs.Navigator>
    </>
  );
}
