import { FC } from "react";
import { View } from "react-native";
import { Icon, Text } from "react-native-paper";
import StyledText from "./StyledText";

import cardDetailsStyles from "../../Styles/cardDetailsStyles";

interface ScoreProps {
  score?: number;
  label: "bold" | "normal" | "none";
}

const starIcon = (nthStar: number, score: number) => {
  const quintile = Math.floor(score / 20); // number of stars
  const decile = Math.floor(score / 10); // use half star

  if (nthStar <= quintile) {
    return "star";
  }
  if (nthStar * 2 - 1 === decile) {
    return "star-half-full";
  }

  return "star-outline";
};

const Score: FC<ScoreProps> = ({ score, label }) => {
  if (!score) {
    return null;
  }

  const stars = [1, 2, 3, 4, 5];

  return (
    <View style={cardDetailsStyles.idLine}>
      <View style={{ display: "flex", flexDirection: "row" }}>
        {label === "none" ? null : (
          <StyledText>
            <Text style={{ fontWeight: label }}>
              Score:
            </Text>
            &nbsp;
          </StyledText>
        )}
        {stars.map((star) => (
          <Icon key={star} source={starIcon(star, score)} size={16} />
        ))}
      </View>
    </View>
  );
};

export default Score;
