import { Timestamp } from "firebase/firestore";

import { Address } from "./address";
import { Contact } from "./contact";
import { SubjectStatus } from "./subjectStatuses";

export const ResidenceCategories = [
  "Trust",
  "Living Trust",
  "Irrevocable Trust",
  "Revocable Trust",
  "Business",
  "Rental",
  "Multiple Homes",
  "In State",
  "Out of State",
  "Deceased",
] as const;

export type ResidenceCategory = (typeof ResidenceCategories)[number];

export type DeceasedResident = {
  dod: string;
  age: number;
  city: string;
  state: string;
  longName: string;
  firstName: string;
  lastName: string;
  legacycomHref?: string;
  legacycomId?: number;
}

export type ResidenceDeath = {
  ownersLiving: number;
  deceased: DeceasedResident[];
}

export type ResidenceMetadata = {
  cleanAddress: string;
  mailingAddress: string;
  resPlaceId: string;
  rentalActivityYear?: number;
  rentalPropertyOwnerCurrentAddresses?: string[];
  ownerOccupied?: boolean;
  mpr_id?: string;
};

export type ResidenceAttributes = Address & {
  name: string;
  category: ResidenceCategory;
  status: SubjectStatus;
  latitude: number;
  longitude: number;
  score: number;
  exemption: number;
  groupId: string;
  parcelId: string;
  uniqueId: string;
  contact: Contact;
  metadata: ResidenceMetadata;
  assigneeId?: string;
};

export type Residence = ResidenceAttributes & {
  id: string;
  originalName?: string;
  createdAt: Timestamp;
  death: ResidenceDeath;
};
