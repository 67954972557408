import { ComponentProps, FC } from "react";
import { Text } from "react-native-paper";

interface DisplayLabelProps {
  text: string;
  style?: ComponentProps<typeof Text>["style"];
}

const DisplayLabel: FC<DisplayLabelProps> = ({ text, style }) => {
  return (
    <Text
      style={[{
        fontSize: 14,
        marginTop: 6,
        marginBottom: 6,
        fontWeight: "700",
      }, style]}
    >
      { text }
    </Text>
  );
}

export default DisplayLabel;
