import { MD3DarkTheme, MD3LightTheme, useTheme } from "react-native-paper";

const snackbarSuccess = "#2DBD09";
const newMarkerColor = "#CFFC81";
const closedMarkerColor = "#7285B4";
const activeMarkerColor = "#55D6B5";
const dataReviewMarkerColor = "#F07D43";
const fieldReviewMarkerColor = "#FED4E7";
const chipTextColor = "#3A3B3D";
const homeBasedColor = "#44AB90";
const residentBusinessMarkerColor = "#81CFFC";
const residentRentalMarkerColor = "#6BC2F4";
const irrevTrustMarkerColor = "#CFFC81";
const revocTrustMarkerColor = "#B5DD71";
const livingTrustMarkerColor = "#9BBD61";
const trustMarkerColor = "#9BBD61";
const multipleHomesMarkerColor = "#55D6B5";
const multipleOutStateMarkerColor = "#40A188";
const multipleInStateMarkerColor = "#4ABB9E";
const deceasedMarkerColor = "#A0BAB3";
const caseCreatedColor = "#00A000";
const caseDismissedColor = "#8F8F8F";

export const darkTheme = {
  ...MD3DarkTheme,
  colors: {
    ...MD3DarkTheme.colors,
    primary: "#1172bb",
    onPrimary: "#FFFFFF",
    error: "#BD3F09",
    background: "#00101B",
    backgroundBorder: "rgba(255, 255, 255, 0.23)",
    handleColor: "#002E4B",
    snackbarError: "red",
    snackbarSuccess,
    newMarkerColor,
    closedMarkerColor,
    activeMarkerColor,
    dataReviewMarkerColor,
    fieldReviewMarkerColor,
    chipTextColor,
    homeBasedColor,
    residentBusinessMarkerColor,
    residentRentalMarkerColor,
    irrevTrustMarkerColor,
    revocTrustMarkerColor,
    livingTrustMarkerColor,
    trustMarkerColor,
    multipleHomesMarkerColor,
    multipleInStateMarkerColor,
    multipleOutStateMarkerColor,
    deceasedMarkerColor,
    caseCreatedColor,
    caseDismissedColor,
  },
};

export const lightTheme = {
  ...MD3LightTheme,
  colors: {
    ...MD3LightTheme.colors,
    primary: "#1172bb",
    onPrimary: "#FFFFFF",
    background: "white",
    backgroundBorder: "rgba(0, 0, 0, 0.23)",
    handleColor: "#E6E6E6",
    error: "orange",
    snackbarError: "red",
    snackbarSuccess,
    newMarkerColor,
    closedMarkerColor,
    activeMarkerColor,
    dataReviewMarkerColor,
    fieldReviewMarkerColor,
    chipTextColor,
    homeBasedColor,
    residentBusinessMarkerColor,
    residentRentalMarkerColor,
    irrevTrustMarkerColor,
    revocTrustMarkerColor,
    livingTrustMarkerColor,
    trustMarkerColor,
    multipleHomesMarkerColor,
    multipleInStateMarkerColor,
    multipleOutStateMarkerColor,
    deceasedMarkerColor,
    caseCreatedColor,
    caseDismissedColor,
  },
};

export const testTheme = {
  ...MD3DarkTheme,
  colors: {
    ...MD3DarkTheme.colors,
    primary: "#1172bb",
    onPrimary: "white",
    error: "#BD3F09",
    background: "#00101B",
    backgroundBorder: "rgba(255, 255, 255, 0.23)",
    handleColor: "#002E4B",
    snackbarSuccess,
    snackbarError: "red",
    newMarkerColor: "newColor",
    closedMarkerColor: "closedColor",
    activeMarkerColor: "activeColor",
    dataReviewMarkerColor: "dataReviewColor",
    fieldReviewMarkerColor: "fieldReviewColor",
    chipTextColor,
    homeBasedColor,
  },
};

export type AppTheme = typeof darkTheme;

export const useAppTheme = () => useTheme<AppTheme>();
