import { FC, useCallback, useState } from "react";
import { TouchableOpacity } from "react-native";
import { Checkbox, IconButton, Menu, Text } from "react-native-paper";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useAppTheme } from "../../theme";
import {
  selectBusinessZipCodes,
  selectResidenceZipCodes,
  selectFilterZipCodes,
  setFilterZipCodes,
} from "../../redux/zipCodesSlice";

type ZipCodeFilterButtonProps = {
  type: "Business" | "Residence";
};

const ZipCodeFilterButton: FC<ZipCodeFilterButtonProps> = ({ type }) => {
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const zipCodes = useAppSelector(
    type === "Business" ? selectBusinessZipCodes : selectResidenceZipCodes
  );
  const selectedZipCodes = useAppSelector(selectFilterZipCodes);

  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const handleZipCodeFilterChange = useCallback(
    (zip: string) => {
      dispatch(setFilterZipCodes(zip));
    },
    [dispatch]
  );

  return (
    <Menu
      visible={isOpen}
      onDismiss={toggleIsOpen}
      anchor={
        <IconButton
          icon="map"
          iconColor={theme.colors.onBackground}
          mode="contained"
          containerColor={theme.colors.background}
          accessibilityLabel="filter"
          style={{
            borderRadius: 4,
            borderWidth: 1,
            borderColor: theme.colors.backgroundBorder,
            height: 40,
          }}
          onPress={toggleIsOpen}
        />
      }
    >
      <Text style={{ fontSize: 16, paddingLeft: 10 }}>Filter by Zip:</Text>
      {zipCodes.map((zip) => (
        <TouchableOpacity
          key={zip}
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginVertical: 4,
          }}
          onPress={() => handleZipCodeFilterChange(zip)}
        >
          <Checkbox.Android
            status={selectedZipCodes.includes(zip) ? "checked" : "unchecked"}
            color="#3988BD"
          />
          <Text variant="bodyLarge" style={{ marginRight: 8 }}>
            {zip}
          </Text>
        </TouchableOpacity>
      ))}
    </Menu>
  );
};

export default ZipCodeFilterButton;
