import Fuse from "fuse.js";

import { Subject, Business, Residence } from "../types";

export const filterByAssigneesFunc = <S extends Subject>(
  subjects: S[],
  assigneeIds: string[]
): S[] => {
  if (!assigneeIds.length) return subjects;

  return subjects.filter((s) => assigneeIds.includes(s.assigneeId || ""));
};

export const filterByZipCodesFunc = <S extends Subject>(
  subjects: S[],
  selectedZipCodeLabels: string[],
  zipCodeLabels: string[]
): S[] => {
  const selectedZipCodes = selectedZipCodeLabels.map((label) => label.substring(0, 5));
  const zipCodes = zipCodeLabels.map((label) => label.substring(0, 5));

  if (!selectedZipCodes.length) return subjects;

  const zipFilteredSubjects = subjects
    .filter((s) => selectedZipCodes.includes(String(s.zipCode).substring(0, 5)));

  if (selectedZipCodes.includes("Other")) {
    const otherZipFilteredSubjects = subjects
      .filter((s) => !zipCodes.includes(String(s.zipCode).substring(0, 5)));

    return [...zipFilteredSubjects, ...otherZipFilteredSubjects];
  }

  return zipFilteredSubjects;
};



export const makeFilterSubjectsFunc =
  (subjectCategories: ReadonlyArray<string>) =>
  <S extends Subject>(subjects: S[], options: string[]): S[] => {
    const categories = options.filter((o) => subjectCategories.includes(o));
    const orderBy = categories.length === 1 && categories[0] === "New" ? "score" : null;

    const filtered = subjects.filter((s) => {
      if (!options.length) return true; // if there are no options

      return categories.length ? categories.includes(s.category) : true;
    });

    return filtered.sort((a, b) => {
      if (orderBy !== "score") return 0;
      if ("score" in a && "score" in b) {
        return (b as Residence).score - (a as Residence).score;
      }
      if ("metadata" in a && "metadata" in b) {
        return (
          Number((b as Business).metadata?.Score || "0") -
          Number((a as Business).metadata?.Score || "0")
        );
      }
      return 0;
    });
  };

export const makeSearchSubjectsFunc =
  (keys: string[]) =>
  <S extends Subject>(subjects: S[], search: string): S[] => {
    if (search === "") return subjects;

    const fuse = new Fuse(subjects, {
      keys,
      isCaseSensitive: false,
      includeScore: true,
      threshold: 0.4,
    });

    return fuse.search(search).map((s) => s.item);
  };
