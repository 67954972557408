import { AppTheme } from "../theme";
import { BusinessCategory } from "./types/business";
import { ResidenceCategory } from "./types/residence";
import { SubjectStatus, SubjectStatuses } from "./types/subjectStatuses";

export const getBusinessStatusColor = (theme: AppTheme, status: BusinessCategory) => {
  switch (status) {
    case "Closed":
      return theme.colors.closedMarkerColor;
    case "Active":
      return theme.colors.activeMarkerColor;
    case "Data Review":
      return theme.colors.dataReviewMarkerColor;
    case "Field Review":
      return theme.colors.fieldReviewMarkerColor;
    case "Home Based":
      return theme.colors.homeBasedColor;
    default:
      return theme.colors.newMarkerColor;
  }
};

export const getResidenceStatusColor = (theme: AppTheme, status: ResidenceCategory) => {
  switch (status) {
    case "Rental":
      return theme.colors.residentRentalMarkerColor;
    case "Business":
      return theme.colors.residentBusinessMarkerColor;
    case "Irrevocable Trust":
      return theme.colors.irrevTrustMarkerColor;
    case "Revocable Trust":
      return theme.colors.revocTrustMarkerColor;
    case "Living Trust":
      return theme.colors.livingTrustMarkerColor;
    case "Trust":
      return theme.colors.trustMarkerColor;
    case "Multiple Homes":
      return theme.colors.multipleHomesMarkerColor;
    case "In State":
      return theme.colors.multipleInStateMarkerColor;
    case "Out of State":
      return theme.colors.multipleOutStateMarkerColor;
    case "Deceased":
      return theme.colors.deceasedMarkerColor;
  }
};

export const terminalSubjectStatusColor = (theme: AppTheme, status: SubjectStatus) => {
  switch (status) {
    case SubjectStatuses.DISMISSED:
      return theme.colors.caseDismissedColor;
    case SubjectStatuses.CASE_CREATED:
      return theme.colors.caseCreatedColor;
    default:
      return theme.colors.newMarkerColor;
  }
};
