import { useCallback, useMemo, useState } from "react";
import { TextInput } from "react-native-paper";
import debounce from "lodash.debounce";

import { useAppTheme } from "../../theme";
import useAppBottomSheet from "../../hooks/useAppBottomSheet";

export type SearchBoxProps = {
  onChange: (text: string) => unknown;
  initialValue?: string;
};

const SearchBox: React.FC<SearchBoxProps> = ({
  onChange,
  initialValue = "",
}) => {
  const theme = useAppTheme();
  const bottomSheet = useAppBottomSheet();
  const [search, setSearch] = useState(initialValue);

  const debouncedOnChange = useMemo(
    () => debounce(onChange, 300),
    [onChange],
  );

  const handleChange = useCallback((text: string) => {
    setSearch(text);
    debouncedOnChange(text);
  }, [debouncedOnChange]);

  const handleFocus = useCallback(() => {
    bottomSheet?.snapToIndex(3);
  }, [bottomSheet]);

  return (
    <TextInput
      value={search}
      onFocus={handleFocus}
      underlineColor="transparent"
      onChangeText={handleChange}
      placeholder="Search"
      placeholderTextColor={theme.colors.onBackground}
      autoCapitalize="words"
      style={{
        width: "100%",
        borderRadius: 4,
        borderWidth: 1,
        borderColor: theme.colors.backgroundBorder,
        backgroundColor: theme.colors.background,
        color: theme.colors.onBackground,
        height: 40,
        marginLeft: 6,
      }}
    />
  );
};

export default SearchBox;
