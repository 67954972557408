import React, { useCallback, useEffect } from "react";
import { Platform, StyleSheet, View } from "react-native";
import { IconButton } from "react-native-paper";

import { useCurrentOrganization } from "@reasongcp/react-fire-sub";

import { useAppTheme } from "../../theme";
import useAppNavigation from "../../hooks/useAppNavigation";
import useAppBottomSheet from "../../hooks/useAppBottomSheet";
import { setUsersLocation } from "../../redux/userSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import DrawerButton from "../drawerButton";
import OnlyPlatform from "../shared/OnlyPlatform";
import { getBusinessStatusColor, getResidenceStatusColor } from "../../lib/mapUtils";
import { BusinessCategory } from "../../lib/types/business";
import { ResidenceCategory } from "../../lib/types/residence";
import { Subject, SubjectNameType } from "../../lib/types/subject";

import IsomorphicMap, { LatLon, OnUserLocationLoadedArgs } from "./Isomorphic";
import useItemsForPins from "./useItemsForPins";
import useAnimateToSubject from "./useAnimateToSubject";
import { useControllerContext } from "./MapControllerProvider";
import HiddenWarning, { HiddenWarningBanner } from "./HiddenWarning";
import SnapshotSummaryReport from "../shared/SnapshotSummaryReport";

type SubjectMapProps<T extends Subject> = {
  selected: T | null;
  subjects: T[];
  AddSubjectComponent?: React.ComponentType | null;
  contextType: string;
  subjectType: SubjectNameType;
};

const isWeb = Platform.OS === "web";

const stylesheet = StyleSheet.create({
  mapControls: {
    position: "absolute",
    top: 50,
    right: isWeb ? 50 : 15,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  webHiddenWarning: {
    position: "absolute",
    top: 15,
    left: 15,
  },

  drawerButtonWrapper: {
    position: "absolute",
    top: 50,
    left: 15,
  },
});

export default React.memo(function CommonMap<T extends Subject>({
  subjectType,
  selected,
  subjects,
  AddSubjectComponent = null,
}: SubjectMapProps<T>) {
  const theme = useAppTheme();
  const dispatch = useAppDispatch();
  const navigation = useAppNavigation();
  const bottomSheet = useAppBottomSheet();
  const currentOrganization = useCurrentOrganization();

  const usersLocation = useAppSelector(({ user }) => user.usersLocation);

  const { controller } = useControllerContext();
  useAnimateToSubject(selected, controller);

  useEffect(() => {
    if (!currentOrganization) return;

    // After the organization is loaded, navigate to its centroid on the map,
    // but fallback to the user location if one is not set.

    const { latitude, longitude } = currentOrganization;
    const centroid: LatLon = { latitude, longitude };
    const haveCentroid = centroid.latitude && centroid.longitude;

    const mapCenter = haveCentroid ? centroid : usersLocation;
    controller.animateTo(mapCenter);
  }, [currentOrganization, controller, usersLocation]);

  const handleUserLocationLoaded = useCallback(
    (userLocation: OnUserLocationLoadedArgs) => {
      const { location, granted } = userLocation;
      if (granted) dispatch(setUsersLocation(location));
    },
    [dispatch]
  );

  const [cappedSubjects, hasHiddenItems] = useItemsForPins(subjects);

  const handleMarkerPress = useCallback(
    (s: T) => {
      navigation.navigate(`${subjectType}_Detail`, { id: s.id });
      bottomSheet?.snapToIndex(2);
    },
    [bottomSheet, navigation, subjectType]
  );

  const handleMapPress = useCallback(
    () => navigation.navigate(`${subjectType}_List`),
    [navigation, subjectType]
  );

  const handleUserLocationPress = useCallback(() => {
    const { latitude, longitude } = usersLocation;
    controller.animateTo({ latitude, longitude });
  }, [usersLocation, controller]);

  const colorForPin = useCallback(
    ({ category }: Subject) => {
      switch (subjectType) {
        case "Business":
          return (
            getBusinessStatusColor(theme, category as BusinessCategory) || "lightTheme"
          );
        case "Residence":
          return (
            getResidenceStatusColor(theme, category as ResidenceCategory) || "lightTheme"
          );
        default:
          return "lightTheme";
      }
    },
    [theme, subjectType]
  );

  return (
    <>
      <IsomorphicMap<T>
        items={cappedSubjects}
        selected={selected}
        onMarkerPress={handleMarkerPress}
        onMapPress={handleMapPress}
        onUserLocationLoaded={handleUserLocationLoaded}
        getPinColorForItem={colorForPin}
      />

      <OnlyPlatform.Mobile>
        <View style={[stylesheet.drawerButtonWrapper]}>
          <DrawerButton />
        </View>
      </OnlyPlatform.Mobile>

      <OnlyPlatform.Web>
        <View style={[stylesheet.webHiddenWarning]}>
          <HiddenWarningBanner hiddenCount={hasHiddenItems} />
        </View>
      </OnlyPlatform.Web>

      <View style={[stylesheet.mapControls]}>
        <SnapshotSummaryReport type={subjectType} />
        {AddSubjectComponent && (
          <AddSubjectComponent />
        )}
        <View>
          <IconButton
            icon="crosshairs-gps"
            size={30}
            style={{
              backgroundColor: "transparent",
            }}
            iconColor={theme.colors.onBackground}
            onPress={handleUserLocationPress}
          />
        </View>

        <OnlyPlatform.Mobile>
          <View>
            <HiddenWarning hiddenCount={hasHiddenItems} />
          </View>
        </OnlyPlatform.Mobile>
      </View>
    </>
  );
});
