import { View } from "react-native";
import { Avatar, Text } from "react-native-paper";

import ResidenceChip from "../../components/shared/ResidenceChip";
import { Residence, ResidenceCategory } from "../../lib/types/residence";
import { useAppTheme } from "../../theme";
import { useAssignee } from "../../hooks/useAssignee";
import { AssigneeText } from "../shared/AssigneeText";
import { SubjectStatuses } from "../../lib/types/subjectStatuses";
import TerminalSubjectStatusChip from "../shared/TerminalSubjectChip";
import Score from "../shared/Score";
import StyledText from "../shared/StyledText";
import cardDetailsStyles from "../../Styles/cardDetailsStyles";
import chipWrapperStyle from "../../Styles/chipWrapper";

type ResidencePreviewProps = {
  residence: Residence;
};

type ResidenceIcons = Record<
  ResidenceCategory,
  | "home"
  | "file-document"
  | "file-account"
  | "file-swap"
  | "file-lock"
  | "bank"
  | "home-account"
  | "home-import-outline"
  | "home-export-outline"
  | "home-alert"
>;

const residenceIcons: ResidenceIcons = {
  Trust: "file-document",
  "Living Trust": "file-account",
  "Irrevocable Trust": "file-lock",
  "Revocable Trust": "file-swap",
  Business: "bank",
  Rental: "home-account",
  "Multiple Homes": "home",
  "In State": "home-import-outline",
  "Out of State": "home-export-outline",
  Deceased: "home-alert",
};

const ResidencePreview: React.FC<ResidencePreviewProps> = ({ residence }) => {
  const { onBackground } = useAppTheme().colors;
  const { parcelId = null, uniqueId = null, category, score } = residence;
  if (!score) console.log("score is null for residence " + residence.id);
  const icon = residenceIcons[category];

  const assignee = useAssignee(residence.assigneeId);

  const isTerminal =
    residence.status === SubjectStatuses.CASE_CREATED ||
    residence.status === SubjectStatuses.DISMISSED;

  return (
    <View style={cardDetailsStyles.cardInner}>
      <View style={{ display: "flex", flexDirection: "column" }}>
        <Avatar.Icon icon={icon} color={onBackground} style={cardDetailsStyles.avatar} />
      </View>

      <View style={{ alignItems: "flex-start", flexShrink: 1 }}>
        <Text style={cardDetailsStyles.residenceName}>{residence.name}</Text>

        <View style={chipWrapperStyle.chipWrapper}>
          {isTerminal ? (
            <TerminalSubjectStatusChip status={residence.status} />
          ) : (
            <ResidenceChip category={residence.category} />
          )}
        </View>

        <AssigneeText assignee={assignee} />
        <StyledText>{residence.address}</StyledText>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <StyledText>{residence.city}, </StyledText>
          <StyledText>{residence.stateAbbr} </StyledText>
          <StyledText>{residence.zipCode}</StyledText>
        </View>
        {parcelId && (
          <View style={cardDetailsStyles.idLine}>
            <StyledText>{parcelId}</StyledText>
          </View>
        )}
        {uniqueId && (
          <View style={cardDetailsStyles.idLine}>
            <StyledText>{uniqueId}</StyledText>
          </View>
        )}
        <Score score={score} label="normal" />
      </View>
    </View>
  );
};

export default ResidencePreview;
